<template>
  <v-container>
    <v-row>
      <v-col md="2" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
      <v-col md="3" cols="12" class="new-contact-title pl-md-5 pl-6">Novo Contato</v-col>
    </v-row>
    <v-row>
      <v-col md="2" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
      <v-col md="8">
        <v-row>
          <v-col md="5" cols="12" class="data-contact-title pl-md-5 pl-6">Dados do Contato:</v-col>
          <v-spacer></v-spacer>
          <v-col md="3" xl="2" cols="6" class="pr-1 mr-md-5 btn-account">
            <v-btn class="btnTransfer" :disabled="contactState" @click="selectAccount" :outlined="otherAccounts" color="#D70472" dark>Conta Finago</v-btn>
          </v-col>
          <v-col md="3" xl="2" cols="6" class="btn-account">
            <v-btn @click="selectAccount" :disabled="contactState" class="btnTransfer" color="#00C1C1" :outlined="!otherAccounts" dark >
              Outros bancos
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-form ref="form" lazy-validation class="form">
      <v-row justify="center">
        <v-col md="8" cols="12">
          <v-row justify="left">
            <v-col md="6" cols="12" v-if="otherAccounts || contactState">
              <v-text-field
                label="CNPJ ou CPF"
                validate-on-blur
                v-model="contact.document"
                placeholder="00.000.000/0000-00"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                :rules="documentRules"
              />
            </v-col>
            <v-col md="6" cols="12" v-if="!otherAccounts && !contactState">
              <v-text-field
                label="CNPJ"
                v-model="document"
                validate-on-blur
                placeholder="00.000.000/0000-00"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                :rules="documentRules"
              />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col md="6" cols="12">
              <v-text-field
                label="Titular"
                v-model="contact.name"
                placeholder="Insira o nome do titular"
                validate-on-blur
                :rules="nameRules"
                maxlength="60"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                label="Apelido"
                v-model="contact.nickName"
                placeholder="Insira um apelido"
                maxlength="20"
                :rules="nickNameRules"
              />
            </v-col>
          </v-row>
          <v-row :justify="otherAccounts? 'center' : 'left'">
            <v-col md="4" cols="8" class="mt-3" v-if="otherAccounts">
              <v-autocomplete
                ref="bankName"
                v-model="contact.bank"
                :items="bankNames"
                label="Banco"
                item-text="value"
                validate-on-blur
                item-value="key"
                placeholder="Selecione o banco"
                required
                :rules="bankRules"
              ></v-autocomplete>
            </v-col>
            <v-col :md="otherAccounts ? '2' : '3'" cols="4" class="pr-5">
              <v-text-field
                label="Agência"
                v-model="contact.branch"
                validate-on-blur
                placeholder="000"
                :rules="branchRules"
                v-mask="contact.branch ? branchMask : null"
              />
            </v-col>
            <v-col :md="otherAccounts? '2' : '3'" :cols="otherAccounts ? '3' : '4'" class="pl-5">
              <v-text-field
                label="Conta"
                v-model="contact.account"
                validate-on-blur
                :placeholder="otherAccounts ? '00000' : '00000-0'"
                :rules="accountRules"
                v-mask="contact.account? accountMask : null"
              />
            </v-col>
            <v-col md="1" cols="1" class="pl-5" v-if="otherAccounts">
              <v-text-field
                label="Dígito"
                v-model="contact.digit"
                placeholder="0"
                v-mask="'#'"
              />
            </v-col>
            <v-col md="3" cols="8" class="pr-5 pb-0 pt-5" v-if="otherAccounts">
              <v-select
                ref="account"
                v-model="contact.accountType"
                :items="accounts"
                label="Tipos de conta"
                validate-on-blur
                :rules="accountTypeRules"
                placeholder="Selecione"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="row-buttons">
      <v-col md="2" cols="12"></v-col>
      <v-col md="2" cols="12">
        <v-btn
          @click="$router.push(`/customer/digitalAccount/transfers`)"
          class="button"
          color="#8C8C8C"
          rounded
          outlined
          dark
        >Voltar</v-btn>
      </v-col>
      <v-col md="2" cols="12">
        <v-btn
          v-if="contactState"
          @click="editedContact"
          class="button"
          color="#D70472"
          rounded
          dark
        >Salvar</v-btn>
        <v-btn v-else @click="createContact" class="button" color="#D70472" rounded dark>Adicionar</v-btn>
      </v-col>
    </v-row>

    <v-row class="rowDialog">
      <Dialog
        @closeModal="closeModal"
        @editcontact="editContact"
        @createContact="createContact"
        @update="update"
        :favorited="favorite"
        :dialog="dialog"
        :updated="updated"
        :edited="edited"
        :error="error"
        :noFavorited="!favorite"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapActions, mapGetters, mapMutations } from "vuex";
import service from '../../../service';
import Dialog from "./modalContact.vue";

export default {
  directives: {
    mask,
  },
  components: {
    Dialog,
  },
  data() {
    return {
      accounts: [
        { text: "Corrente", value: "c" },
        { text: "Poupança", value: "p" },
      ],
      masks: "###.###.###-###",
      branchMask: '####',
      accountMask: '####################',
      otherAccounts: false,
      dialog: false,
      edited: false,
      updated: false,
      favorite: true,
      document: '',
      service,
      contact: {
        document: '',
        bank: '',
        branch: '',
        accountType: '',
        name: '',
        nickName: '',
        account: '',
      },
      documentRules: [
        (valueInput) =>
          (!!valueInput && valueInput.length >= 14) || "Documento obrigatório",
      ],
      bankRules: [(valueInput) => !!valueInput || "Banco obrigatório"],
      branchRules: [
        (valueInput) =>
          (!!valueInput && valueInput.length >= 1) || "Agência obrigatória",
      ],
      nameRules: [(valueInput) => !!valueInput || "Nome obrigatório"],
      nickNameRules: [(valueInput) => !!valueInput || "Apelido obrigatório"],
      accountRules: [
        (valueInput) =>
          (!!valueInput && valueInput.length >= 1) || "Conta obrigatória",
      ],
      accountTypeRules: [
        (valueInput) => !!valueInput || "Tipo de conta obrigatória",
      ],
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.closeModal();
        this.$emit("closed");
      }
    },
    document(value) {
      this.contact.name = '';
      this.contact.account = '';
      this.contact.branch = '';
      this.contact.accountType = '';
      this.contact.digit = '';
      this.contact.bank = '';
      this.contact.nickName = '';
      if (!this.contact.name) {
        this.handleChange(value);
      }
    }
  },
  computed: {
    ...mapGetters({
      error: "DigitalAccount/getError",
      contactState: "DigitalAccount/getContact",
      getBanks: "Registration/getBanks",
      getContacts: 'DigitalAccount/getContacts',
      role: "Auth/getRole",
      accountTransfer: 'DigitalAccount/getAccount',
      contactCreated: 'DigitalAccount/getContactCreated'
    }),
    bankNames() {
      return this.getBanks;
    },
  },
  methods: {
    ...mapActions({
      getAllContacts: 'DigitalAccount/getAllContacts',
      newContact: "DigitalAccount/createContact",
      updateContact: "DigitalAccount/updateContact",
      setBanks: "Registration/setBanks",
      getAccountByDocument: 'DigitalAccount/getAccountByDocument',
    }),
    ...mapMutations({
      setContact: "DigitalAccount/SET_CONTACT",
      clearError: "DigitalAccount/CLEAR_ERROR",
    }),
    editedContact() {
      if (this.$refs.form.validate()) {
        this.edited = true;
        this.dialog = true;
      }
    },
    async handleChange() {
        if (this.document && this.document.length >= 18) {
          await this.getAccountByDocument(this.service.cleanDocument(this.document));
          if (!this.error) {
            this.populateFormData(this.accountTransfer);
          }
        }
      },
      populateFormData(data) {
      const {
        document,
        name,
        account,
        branch,
      } = data;

      this.contact.document = document;
      this.contact.name = name;
      this.contact.account = account.toString();
      this.contact.branch = branch.toString();
      this.contact.accountType = 'c';
      this.contact.bank = '9999';
    },
    createNickName() {
      const arrayName = this.contact.name.split(" ");
      if (arrayName.length >= 2) {
        this.contact.nickName = `${arrayName.shift()} ${arrayName.pop()}`;
      } else {
        this.contact.nickName = this.contact.name;
      }
    },
    async createContact() {
      if (!this.$refs.form.validate()) return;
      if (!this.contact.nickName) {
        await this.createNickName();
      }

      const payload = {...this.contact, document: this.service.cleanDocument(this.contact.document), digit:parseInt(this.contact.digit)}
      await this.newContact(payload);
      if (this.error) {
        this.dialog = true;
        this.edited = false;
        this.updated = false;
        this.favorite = false;
        return;
      }
      this.contact = {};
      this.favorited = true;
      this.dialog = true;
      },
      async update() {
        if (!this.$refs.form.validate()) return;
        const payload = {...this.contactState, document: this.service.cleanDocument(this.contactState.document), digit:parseInt(this.contact.digit)}

        await this.updateContact(payload);
        if (this.error) return;
        this.edited = false;
        this.updated = true;
      },
      async initialize() {
        if (this.contactState) {
          this.contact = this.contactState;
          this.contact.document = this.service.documentFormat(this.contact.document)
        }
      },
      async closeModal() {
        this.dialog = false;
        this.edited = false;
        this.favorited = false;
        this.updated = false;
        this.clearError();
      },
      async editContact() {
        this.dialog = false;
        if(this.createContact){
          await this.setContact(this.contactCreated);
          await this.contactState;
          await this.initialize();
        }
          await this.setContact(this.contact);
          await this.contactState;
          await this.initialize();
      },
      getAccount() {
        if(this.contact.name)
          if(this.contact.bank === '9999') {
          this.otherAccounts = false
        } else {
          this.otherAccounts = true
        }
      },
      selectAccount() {
        this.otherAccounts = !this.otherAccounts;
        this.contact.document = '';
        this.document = '';
        this.contact.name = '';
        this.contact.account = '';
        this.contact.digit = '';
        this.contact.branch = '';
        this.contact.accountType = '';
        this.contact.bank = '';
        this.contact.nickName = '';
    },
  },
  async created() {
    await this.initialize();
    await this.getAccount();
    await this.setBanks();
    
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/digitalAccount/newContact.scss";
</style>
