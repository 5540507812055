<template>
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="430"
    class="dialog"
  >
    <v-card class="contentCard">
      <v-card-title class="headline">
        <v-col cols="10" class="modal-title" v-if="favorited && !edited && !updated && !error">Novo Contato</v-col>
        <v-col cols="10" class="modal-title" v-if="!error && edited || updated">Editar Contato</v-col>
        <v-col cols="10" class="modal-title" v-if="error">Mensagem de Erro</v-col>
        <v-spacer></v-spacer>
        <v-col class="icon" cols="1">
          <v-icon @click="$emit('closeModal')" class="close-icon">mdi-window-close</v-icon>
        </v-col>
      </v-card-title>
      <v-divider class="mx-6 ml-6"></v-divider>
      <v-col v-if="getLoading" cols="12" align="center">
        <v-progress-circular
        :size="100"
        color="#D70472"
        indeterminate
        ></v-progress-circular>
        <h3 class="textInfoDisable mt-6">Atualizando contato...</h3>
      </v-col>
      <v-card-text v-show="!getLoading">
        <v-row justify="center">
          <v-col md="12">
            <v-row justify="center">
              <img class="imgSuccess mt-md-5 mt-12" src="@/assets/success.svg" v-if="!error && updated" />
              <img class="imgSuccess mt-md-5 mt-12" src="@/assets/blue-info.png" v-if="!error && edited" />
              <img
                class="imgSuccess mt-md-5 mt-12"
                src="@/assets/favorite.svg"
                v-if="favorited && !edited && !updated && !error"
              />
              <img class="imgSuccess mt-md-5 mt-12" src="@/assets/errorBlue.png" v-if="error" />
            </v-row>
          </v-col>
          <v-col cols="9" md="8" class="pb-10">
            <v-row justify="center">
              <span class="title-modal" v-if="!error && updated">Contato atualizado</span>
              <span class="title-modal" v-if="error">{{error}}</span>
              <span class="title-modal" v-if="!error && edited">Confirmação de edição</span>
              <span
                class="subtext-modal pt-3 pl-6 pr-6"
                v-if="!error && edited"
              >Você confirma a alteração dos dados?</span>
              <span
                class="title-modal pl-6 pr-6"
                v-if="favorited && !edited && !updated && !error"
              >Contato adicionado aos favoritos</span>
            </v-row>
          </v-col>
          <v-col cols="12" md="8">
            <v-row justify="center">
              <v-btn
                @click="$router.push(`/customer/digitalAccount/transfers`)"
                rounded
                dark
                color="#00C1C1"
                class="button-modal pa-6 pa-md-1"
                v-if="!edited && favorited && !error || updated && !error"
              >Ir para transferências</v-btn>
              <v-btn
                @click="$emit('update')"
                rounded
                dark
                color="#00C1C1"
                class="button-modal pa-6 pa-md-1"
                v-if="!error && edited"
              >Sim</v-btn>
            </v-row>
          </v-col>
          <v-col cols="12" md="8">
            <v-row justify="center">
              <v-btn
                @click="$emit('editcontact')"
                rounded
                outlined
                color="#8C8C8C"
                class="button-modal pa-6 pa-md-1"
                v-if="!edited && favorited && !error"
              >Editar contato</v-btn>

              <v-btn
                @click="$emit('closeModal')"
                rounded
                color="#8C8C8C"
                outlined
                class="button-modal pa-6 pa-md-1"
                v-if="!error && edited"
                dark
              >Não</v-btn>
              <v-btn
                @click="$emit('closeModal')"
                rounded
                color="#8C8C8C"
                outlined
                class="button-modal pa-6 pa-md-1"
                v-if="error"
                dark
              >Fechar</v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    edited: Boolean,
    updated: Boolean,
    favorited: Boolean,
    dialog: Boolean,
    error: String,
  },
  computed: {
    ...mapGetters({
      role: "Auth/getRole",
      getLoading: "Loading/getLoading"
    }),
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/digitalAccount/dataTransference.scss";
</style>
